import React from "react";
import "./style.scss";

export default function Footer() {
  return (
    <div className="footer-container">
      scroll
    </div>
  );
}
