import ted18 from "./projects/TED2018.jpg";
import ted19 from "./projects/TED2019.jpg";
import mys from "./projects/MYS.jpg";
import stef from "./projects/stef.jpg";
import hugo1 from "./projects/Hugo1.jpg";
import hugo2 from "./projects/hugo2.jpg";
import stray from "./projects/stray.jpg";
import theworld from "./projects/theworld.jpg";
import buttondown from "./projects/buttondown.jpg";
import hospitality from "./projects/hospitality.jpg";

export const pageData = [
  {
    url: ted18,
    title: "tedx 2018",
    info: ["Branding", "Graphics", "Event Planning"],
  },
  {
    url: stef,
    title: "stef stef",
    info: ["Logo", "Brand Identity"],
  },
  {
    url: stray,
    title: "stray fest",
    info: ["Branding", "Graphics", "Event Planning"],
  },
  {
    url: buttondown,
    title: "button down",
    info: ["Graphics", "Creative Direction", "Photography"],
  },
  {
    url: ted19,
    title: "tedx 2019",
    info: ["Branding", "Graphics", "Event Planning"],
  },
  {
    url: hugo1,
    title: "hugo boss",
    info: ["Inspo", "Graphics", "Internship"],
  },
  {
    url: mys,
    title: "mindyourstyle",
    info: ["Graphics", "Prints"],
  },
  {
    url: theworld,
    title: "the world",
    info: ["Photography", "Creative Direction", "Graphics"],
  },
  {
    url: hugo2,
    title: "hugo prints",
    info: ["Inspo", "Graphics", "Internship"],
  },
  {
    url: hospitality,
    title: "hospitality",
    info: ["Logo", "Re-Branding"],
  },
];
