import React from "react";
import "../styles/home.scss";
import Home from "../containers/Home";

const Index = () => {
  return (
    <>
      <Home />
    </>
  );
};
export default Index;
